import React, { Dispatch, useEffect, useRef } from 'react'
import { InputLabel, Switch, Box, CircularProgress } from '@mui/material'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ClearIcon from '@mui/icons-material/Clear'
import { useIntl } from 'react-intl'
import { isFilterUrlValueSet, useFilter } from 'utils/filters'
import { getStylesFilterMenuLabel } from 'styles/contents/FilterMenuLabel'
import { getStylesFilterMenuLabelSwitch } from 'styles/contents/FilterMenuLabelSwitch'
import TooltipTitle from './common/TooltipTitle'

export interface FilterMenuLabelProps {
  filter: FilterCategory
  setCurrentPage: Dispatch<any>
  scope: string
  selectedFilter: string
  checked: any
  setChecked: any
  emptyFilter: any
  setAnchorEl: any
  premiumEnabled: boolean
  setPremiumEnabled: Dispatch<boolean>
  optionsLength: number
  anchorEl: any
  internalBroadcast: string[]
}

export default function FilterMenuLabel(
  props: FilterMenuLabelProps
): JSX.Element {
  const {
    filter,
    setCurrentPage,
    scope,
    selectedFilter,
    checked,
    setChecked,
    emptyFilter,
    setAnchorEl,
    premiumEnabled,
    setPremiumEnabled,
    optionsLength,
    anchorEl,
    internalBroadcast
  } = props

  const classes = getStylesFilterMenuLabel()
  const classesSwitch = getStylesFilterMenuLabelSwitch()
  const intl = useIntl()

  const [filterUrlValue, setFilterUrlValue] = useFilter(filter.key, '')
  const [published] = useFilter('published', '')

  //handle force open/close menu
  const filterLabelRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (
      internalBroadcast &&
      internalBroadcast.includes('openFilterMenu_' + filter.key) &&
      anchorEl === null
    )
      setAnchorEl(filterLabelRef.current)

    if (
      internalBroadcast &&
      internalBroadcast.includes('closeFilterMenu_' + filter.key) &&
      anchorEl !== null
    )
      setAnchorEl(null)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [internalBroadcast])

  //close panel on unload component
  useEffect(() => {
    return () => {
      if (anchorEl) setAnchorEl(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  //prevent animations on first load (vertical switch, reload page)
  const [blockAnimation, setBlockAnimation] = React.useState<boolean>(true)
  useEffect(() => {
    const timer = window.setTimeout(() => {
      setBlockAnimation(false)
    }, 1000)

    return () => {
      clearTimeout(timer)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getFilterValue = (filterName = '') => {
    const selectedFilter =
      filter.options &&
      filter.options.find(
        (option) =>
          (option.key && option.key.toLowerCase()) === filterName.toLowerCase()
      )
    if (!selectedFilter) {
      return filterName
    }
    return selectedFilter.name
  }

  function handlePremiumChange(checked: any) {
    if (premiumEnabled) {
      checked = false
    } else {
      checked = true
    }

    setChecked(checked)

    if (checked) {
      setFilterUrlValue(checked ? '1' : '0')
      setCurrentPage(1)
      setPremiumEnabled(true)
      return
    }

    setFilterUrlValue('')
    setCurrentPage(1)
    setPremiumEnabled(false)
  }

  const isInValidFilter = () => {
    if (!filterUrlValue && !selectedFilter) return true

    // Dont disable filter selection in case of temporay filters
    if (filterUrlValue === 'none') {
      return false
    }

    if (filter.multiselect) {
      if (!filterUrlValue) return true
      if (getNumEnabledFilters() === 0) return true
    } else if (!filterUrlValue) {
      // Search for the selected filter value in options by the selected Filter Value
      const filterSelected =
        filter.options &&
        filter.options.find(
          (option) =>
            isFilterUrlValueSet(filter, selectedFilter, option.key) &&
            selectedFilter &&
            selectedFilter.toLowerCase() !== 'all'
        )

      return !filterSelected
    } else {
      // get the filter by url value
      let filterSelected =
        filter.options &&
        filter.options.find((option) => {
          // Make additional mapping for the news language filter in case of zht and ptb filterurlvalues,
          // which must be mapped to existing option zhs or ptp
          if (
            (scope === 'news' || scope === 'marketresearch') &&
            (option.key === 'zhs' || option.key === 'ptp')
          ) {
            const additionalKeyToCheck = option.key === 'zhs' ? 'zht' : 'ptb'
            return (
              isFilterUrlValueSet(filter, filterUrlValue, option.key) ||
              isFilterUrlValueSet(filter, filterUrlValue, additionalKeyToCheck)
            )
          } else {
            return isFilterUrlValueSet(filter, filterUrlValue, option.key)
          }
        })

      // if not found and the selectedFilter is present try to find the filter value
      if (!filterSelected && selectedFilter) {
        filterSelected =
          filter.options &&
          filter.options.find((option) =>
            isFilterUrlValueSet(filter, selectedFilter, option.key)
          )
      }

      return !filterSelected
    }
  }

  const getSelectedFilter = (selectedFilterText?: string) => {
    const filterSearchValue = selectedFilterText || filterUrlValue
    let selectedFilterItem = undefined
    if (!filterSearchValue && !selectedFilter) {
      selectedFilterItem =
        filter.options &&
        filter.options.find((option) => option.isDefaultFilter)
    }

    if (!selectedFilterItem && !filterSearchValue && selectedFilter) {
      selectedFilterItem =
        filter.options &&
        filter.options.find((option) =>
          isFilterUrlValueSet(filter, selectedFilter, option.key)
        )
    }

    if (!selectedFilterItem) {
      selectedFilterItem =
        filter.options &&
        filter.options.find((option) => {
          // Make additional mapping for the news language filter in case of zht and ptb filterurlvalues,
          // which must be mapped to existing option zhs or ptp
          if (
            (scope === 'news' || scope === 'marketresearch') &&
            (option.key === 'zhs' || option.key === 'ptp')
          ) {
            const additionalKeyToCheck = option.key === 'zhs' ? 'zht' : 'ptb'
            return (
              isFilterUrlValueSet(filter, filterUrlValue, option.key) ||
              isFilterUrlValueSet(filter, filterUrlValue, additionalKeyToCheck)
            )
          } else {
            return isFilterUrlValueSet(filter, filterUrlValue, option.key)
          }
        })
    }

    if (!selectedFilterItem) {
      // try to get the default filter
      selectedFilterItem =
        filter.options &&
        filter.options.find((option) => option.isDefaultFilter)

      // as default fallback return the first filter in the options
      if (!selectedFilterItem) {
        return filter.options && filter.options.length > 0
          ? filter.options[0].name
          : ''
      }
    }
    return selectedFilterItem.name || ''
  }

  const getNumEnabledFilters = (): number => {
    let numEnabledFilters = 0
    if (filter && filter.multiselect && filterUrlValue) {
      let setFilters = filterUrlValue.split('|')
      // filter by available
      setFilters = setFilters.filter((f: string) => {
        return filter.options.some((opt: Filter) => {
          return opt.key === f
        })
      })
      numEnabledFilters = setFilters ? setFilters.length : 0
    }
    return numEnabledFilters
  }

  const toggleFilterChecked = () => {
    setChecked(!checked)

    setFilterUrlValue(!checked ? '1' : '')
    setCurrentPage(1)
  }

  return (
    <>
      {filter && filter.key && filter.key === 'country' && (
        <Switch
          id={'inp-' + filter.key + '-01'}
          classes={{
            ...classesSwitch,
            switchBase: `${classesSwitch.switchBase} ${
              blockAnimation ? classes.disabledAnimation : ''
            }`
          }}
          color="primary"
          checked={checked}
          onChange={() => {
            emptyFilter(filter.key, !checked, false)
          }}
          disabled={filter.key === 'country' && isInValidFilter()}
        />
      )}
      {filter && filter.key && filter.key === 'premium' && (
        <Switch
          id={'inp-' + filter.key + '-01'}
          classes={{
            ...classesSwitch,
            switchBase: `${classesSwitch.switchBase} ${
              blockAnimation ? classes.disabledAnimation : ''
            }`
          }}
          color="primary"
          checked={premiumEnabled}
          onChange={() => {
            handlePremiumChange(!checked)
          }}
        />
      )}
      {filter && filter.key && filter.isToggleFilter && (
        <Switch
          id={'inp-' + filter.key + '-01'}
          classes={classesSwitch}
          color="primary"
          checked={checked}
          onChange={() => {
            toggleFilterChecked()
          }}
        />
      )}

      <InputLabel
        classes={
          optionsLength > 0 && !filter.singleOption
            ? { root: classes.inputlabel }
            : { root: classes.inputlabelnopointer }
        }
        className={
          filter.key === 'premium'
            ? classes.selectedText
            : filter.isUpdating || optionsLength === 0
              ? classes.menuDisabled
              : classes.selectedText
        }
        onClick={(event) => {
          if (!filter.isUpdating) {
            if (!filter.singleOption) {
              //open menu
              setAnchorEl(event.currentTarget)
            }
          }
        }}
        shrink={false}
      >
        {isInValidFilter() ? (
          <div
            id={'flt-' + filter.key + '-01'}
            className={
              checked === true ? classes.selectedText : classes.notSelected
            }
          >
            {published === 'true' &&
            scope === 'intranet' &&
            filter.key === 'lastmodified'
              ? intl.formatMessage({
                  id: 'last_published',
                  defaultMessage: 'Last published'
                })
              : intl.formatMessage({
                  id:
                    getFilterValue(filter.name)
                      .toLowerCase()
                      .replace(/\s/g, '_') !== ''
                      ? getFilterValue(filter.name)
                          .toLowerCase()
                          .replace(/\s/g, '_')
                          .replace(/[\u200B-\u200D\uFEFF]/g, '')
                      : 'error',
                  defaultMessage: getFilterValue(filter.name)
                })}
            <div ref={filterLabelRef} />
          </div>
        ) : (
          <>
            <div id={'flt-' + filter.key + '-01'}>
              <TooltipTitle
                title={intl.formatMessage({
                  id:
                    getSelectedFilter().toLowerCase().replace(/\s/g, '_') !== ''
                      ? getSelectedFilter().toLowerCase().replace(/\s/g, '_')
                      : 'error',
                  defaultMessage: getSelectedFilter()
                })}
                useCssOnly={true}
                singleLine={true}
                additionalClass={`${
                  checked === true ? classes.selectedText : classes.notSelected
                } ${classes.filterLabel}`}
                checkSizeOnTitleChange={true}
              />
            </div>
            {filter.multiselect && getNumEnabledFilters() > 1 && (
              <div>&nbsp;(+{getNumEnabledFilters() - 1})</div>
            )}
            <div ref={filterLabelRef} />
          </>
        )}

        {filter.isUpdating && filter.isUpdating === true ? (
          <Box className={classes.loadingSpinnerWrapper}>
            <CircularProgress className={classes.loadingSpinner} size={10} />
          </Box>
        ) : (
          filter &&
          filter.key &&
          filter.key !== 'premium' &&
          !filter.isToggleFilter &&
          (!filter.singleOption ? (
            <ArrowDropDownIcon
              className={classes.downArrow}
              fontSize={'small'}
            />
          ) : (
            <ClearIcon
              className={`${classes.downArrow} ${classes.singleOptionIcon}`}
              fontSize={'small'}
              onClick={(event) => {
                // remove filter
                emptyFilter(filter.key, !checked, false)
              }}
            />
          ))
        )}
      </InputLabel>
    </>
  )
}
