import React, {
  Dispatch,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import {
  Box,
  CircularProgress,
  Fade,
  FormControlLabel,
  Radio,
  RadioGroup
} from '@mui/material'
import Menu from '@mui/material/Menu'
import { useIntl } from 'react-intl'
import { AutoSizer, List } from 'react-virtualized'
import { trackException } from 'utils/tracking'
import FilterMenuSearchInput from './FilterMenuSearchInput'
import {
  getCountryKeyFromName,
  isFilterUrlValueSet,
  sortFilterOptions,
  useFilter
} from 'utils/filters'
import FilterMenuLabel from './FilterMenuLabel'
import FilterMenuItems from './FilterMenuItems'
import { IUserSetting } from 'utils/userSettings'
import { getStylesFilterMenu } from 'styles/contents/FilterMenu'
import { getStylesFilterMenuStyledMenu } from 'styles/contents/FilterMenuStyledMenu'
import { Button } from '@mui/material'
import FilterMenuPeopleSearch from './FilterMenuPeopleSearch'
import { useLocation } from 'react-router-dom'

export interface FilterMenuProps {
  filter: FilterCategory
  recentFilters: any
  setRecentFilters: Dispatch<any>
  clearRecentFilters: Dispatch<any>
  setCurrentPage: Dispatch<any>
  scope: string
  userSettings: IUserSetting
  premiumEnabled: boolean
  setPremiumEnabled: Dispatch<boolean>
  selectedFilter: string
  setSelectedFilter: any
  modifiedRangeFilters: any
  internalBroadcast: string[]
  closeCallBack?: () => void
  doCloseMenu?: () => void
}

export default function FilterMenu(props: FilterMenuProps): JSX.Element {
  const {
    filter,
    recentFilters,
    setRecentFilters,
    clearRecentFilters,
    setCurrentPage,
    scope,
    userSettings,
    premiumEnabled,
    setPremiumEnabled,
    selectedFilter,
    setSelectedFilter,
    modifiedRangeFilters,
    closeCallBack,
    internalBroadcast
  } = props

  const minFilterLength = filter.options && filter.options.length > 10

  const menuStyles = getStylesFilterMenuStyledMenu()
  const classes = getStylesFilterMenu()
  const location = useLocation()
  const intl = useIntl()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [filterUrlValue, setFilterUrlValue] = useFilter(filter.key, '')
  const [published, setPublished] = useFilter('published', '')
  const [checked, setChecked] = useState<boolean>(false)
  const [isOpen, setIsOpen] = useState<boolean>(false) //fix for autodetect overflowing text
  const [localFilterOptions, setLocalFilterOptions] = useState<Filter[]>(
    filter.options
  )
  const [searchedLocalFilterOptions, setSearchedLocalFilterOptions] = useState<
    Filter[]
  >(filter.options)
  const [searchOptions, setSearchOptions] = useState<{
    isSearched: boolean
    searchTerm: string | null
  }>({
    isSearched: false,
    searchTerm: null
  })
  const [localRecentFilterOptions, setLocalRecentFilterOptions] = useState<
    Filter[]
  >([])
  const [
    searchedLocalRecentFilterOptions,
    setSearchedLocalRecentFilterOptions
  ] = useState<Filter[]>([])

  const menuItemRef = useRef<HTMLLIElement | null>(null)

  const markFilterOptionAsSelected = useCallback(
    (filter: FilterCategory) => {
      return filter.options.map((option: any) => {
        option['selected'] = undefined

        // Make additional mapping for the news language filter in case of zht and ptb filterurlvalues,
        // which must be mapped to existing option zhs or ptp
        if (
          (scope === 'news' || scope === 'marketresearch') &&
          (option.key === 'zhs' || option.key === 'ptp')
        ) {
          const additionalKeyToCheck = option.key === 'zhs' ? 'zht' : 'ptb'
          if (
            isFilterUrlValueSet(filter, filterUrlValue, option.key) ||
            isFilterUrlValueSet(filter, filterUrlValue, additionalKeyToCheck)
          ) {
            option['selected'] = true
          }
        } else {
          if (!filter.multiselect) {
            if (
              isFilterUrlValueSet(filter, filterUrlValue, option.key) &&
              isFilterUrlValueSet(filter, filterUrlValue, selectedFilter)
            ) {
              option['selected'] = true
            } else if (!filterUrlValue && option.key === '') {
              option['selected'] = true
            }
          } else {
            if (
              selectedFilter &&
              selectedFilter
                .split('|')
                .some((key: string) => option.key === key)
            ) {
              option['selected'] = true
            }
          }
        }

        return option
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filterUrlValue, selectedFilter]
  )

  function sortSelectedFilterOptions(options: Filter[]) {
    return options.sort((a, b) =>
      a.hasOwnProperty('selected') ? 0 : b.hasOwnProperty('selected') ? 1 : 0
    )
  }

  const getSearchResults = (debouncedSearchTerm: string) => {
    const searchedOptions = localFilterOptions.filter((option: any) => {
      const formattedOptionName = intl
        .formatMessage({
          id: option.name.toLowerCase().replace(/\s/g, '_'),
          defaultMessage: option.name.toLowerCase()
        })
        .toLowerCase()
      return formattedOptionName.includes(
        (debouncedSearchTerm.toLowerCase() || '').toLowerCase()
      )
    })

    const searchedRecentFilterOptions = localRecentFilterOptions.filter(
      (option: any) => {
        const formattedOptionName = intl
          .formatMessage({
            id: option.name.toLowerCase().replace(/\s/g, '_'),
            defaultMessage: option.name.toLowerCase()
          })
          .toLowerCase()
        return formattedOptionName.includes(
          (debouncedSearchTerm.toLowerCase() || '').toLowerCase()
        )
      }
    )

    return [...searchedRecentFilterOptions, ...searchedOptions]
  }

  const handleSearch = useCallback(
    (debouncedSearchTerm: string) => {
      if (debouncedSearchTerm && debouncedSearchTerm !== '') {
        const searchResults = getSearchResults(debouncedSearchTerm)

        setSearchOptions({
          isSearched: true,
          searchTerm: debouncedSearchTerm
        })
        setSearchedLocalRecentFilterOptions([])
        setSearchedLocalFilterOptions(searchResults)
      } else {
        setSearchOptions({
          isSearched: false,
          searchTerm: debouncedSearchTerm
        })
        setSearchedLocalRecentFilterOptions(localRecentFilterOptions)
        setSearchedLocalFilterOptions(localFilterOptions)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [intl, localFilterOptions, localRecentFilterOptions]
  )

  useEffect(() => {
    const sortedOptions = !minFilterLength
      ? markFilterOptionAsSelected(filter)
      : sortSelectedFilterOptions(markFilterOptionAsSelected(filter))
    const recentOptions = [] as any[]
    let otherOptions = [] as any[]

    if (minFilterLength) {
      sortedOptions.forEach((item: any) => {
        if (item.isDefaultFilter) {
          //add default filter option on top of recent filters
          recentOptions.push(item)
        }
      })
      sortedOptions.forEach((item: any) => {
        if (
          recentFilters[filter.key] !== undefined &&
          recentFilters[filter.key].indexOf(item.key) !== -1 &&
          !item.isDefaultFilter
        ) {
          recentOptions.push(item)
        } else if (
          (recentFilters[filter.key] === undefined ||
            recentFilters[filter.key].indexOf(item.key) === -1) &&
          !item.isDefaultFilter
        ) {
          otherOptions.push(item)
        }
      })
    } else {
      otherOptions = sortedOptions
    }

    setLocalRecentFilterOptions(recentOptions)
    setLocalFilterOptions(otherOptions)

    if (
      filter.multiselect &&
      searchOptions.isSearched &&
      searchOptions.searchTerm
    ) {
      const searchResults = getSearchResults(searchOptions.searchTerm)

      setSearchedLocalRecentFilterOptions([])
      setSearchedLocalFilterOptions(searchResults)
    } else {
      setSearchedLocalRecentFilterOptions(recentOptions)
      setSearchedLocalFilterOptions(otherOptions)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filter,
    filterUrlValue,
    intl.locale,
    minFilterLength,
    recentFilters,
    markFilterOptionAsSelected
  ])

  useEffect(() => {
    // the filter toggle should only be checked if an existing filter is applied
    // and the filterUrlValue is set, in all other cases not
    if (filter.key === 'premium') {
      const premiumEnabled = filterUrlValue === '1'

      setChecked(premiumEnabled)
    } else if (filter.key === 'cfrom') {
      setChecked(Boolean(filterUrlValue))
    } else {
      const selectedFilter =
        filter.options &&
        filter.options.find((option) =>
          isFilterUrlValueSet(filter, filterUrlValue, option.key)
        )
      setChecked(Boolean(selectedFilter && filterUrlValue))

      if (filterUrlValue || filterUrlValue === '') {
        setSelectedFilter(filterUrlValue, filter.key)
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterUrlValue, filter])

  const handleClose = (useCallBack?: boolean) => {
    setAnchorEl(null)
    if (useCallBack && closeCallBack) {
      closeCallBack()
    }
  }

  function emptyFilter(
    key: string,
    checked: boolean,
    shouldClearFilter?: boolean
  ) {
    try {
      if (checked) {
        setFilterUrlValue(selectedFilter)
        setCurrentPage(1)
        return
      }
      if (shouldClearFilter && !checked) {
        clearRecentFilters(key)
        handleClose()
        setSelectedFilter('', filter.key)
      }

      filter.options.forEach((option: any) => {
        if ('selected' in option) {
          'key' in option && option['key'] !== key
            ? delete option['selected']
            : delete option['selected']
        }
      })

      setFilterUrlValue('')
      setCurrentPage(1)
    } catch (error) {
      trackException('Error in emptyFilter method in FilterMenu.tsx', error)
      return <></>
    }
  }

  const transformFilterOptions = useMemo(() => {
    return [
      ...(minFilterLength && searchedLocalRecentFilterOptions
        ? searchedLocalRecentFilterOptions.map((option: any, index: number) => {
            const isLastRecentItem =
              minFilterLength &&
              searchedLocalRecentFilterOptions.length - 1 === index

            return { ...option, isLastRecentItem }
          })
        : []),
      ...(filter.noSorting
        ? sortFilterOptions(searchedLocalFilterOptions) //sort by optional sort property
        : searchedLocalFilterOptions.sort(
            //sort by result count
            scope === 'news' && filter.key !== 'orderBy'
              ? (a, b) => {
                  if (a && a.resultCount && b && b.resultCount) {
                    if (a.resultCount > b.resultCount) {
                      return -1
                    }
                    if (a.resultCount < b.resultCount) {
                      return 1
                    }
                  }
                  return 0
                }
              : (a, b) => a?.name?.localeCompare(b.name, intl.locale) || 1
          ))
    ]
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    intl.locale,
    minFilterLength,
    searchedLocalFilterOptions,
    searchedLocalRecentFilterOptions
  ])

  const rowRenderer = ({ key, index, style }: any) => {
    const option = transformFilterOptions[index]
    if (!option) {
      return
    }

    return (
      <FilterMenuItems
        key={index}
        filter={filter}
        setRecentFilters={setRecentFilters}
        setCurrentPage={setCurrentPage}
        handleClose={handleClose}
        selectedFilter={selectedFilter}
        setSelectedFilter={(value: string) =>
          setSelectedFilter(value, filter.key)
        }
        menuItemRef={menuItemRef}
        option={{ ...option, style }}
        index={index}
        isLastRecentItem={option.isLastRecentItem}
        isFirstMenuItem={index === 0}
        modifiedRangeFilters={modifiedRangeFilters}
      />
    )
  }

  function handleMenuKeyDown() {
    menuItemRef.current && menuItemRef.current.focus({ preventScroll: true })
  }

  function handleClearAllFilterOptions() {
    emptyFilter(filter.key, !checked, true)
    setUserCountryAsSuggestion()
  }

  function handleClearAllMultiSelectFilterOptions() {
    setSelectedFilter('', filter.key)
  }

  function handleApplyMultiSelectFilterOptions() {
    if (searchOptions.isSearched) {
      setSearchOptions({
        isSearched: false,
        searchTerm: null
      })
    }
    handleClose(true)
    setFilterUrlValue(selectedFilter)
    setCurrentPage(1)
  }

  //set user country as default suggestion
  const setUserCountryAsSuggestion = () => {
    if (
      userSettings &&
      userSettings.Country &&
      (selectedFilter === '' || selectedFilter === undefined) &&
      filter.key === 'country'
    ) {
      const curuseroption = filter.options.find(
        (option: any) => option.name === userSettings.Country
      )
      if (curuseroption) {
        curuseroption.isDefaultFilter = true

        setSelectedFilter(
          getCountryKeyFromName(userSettings.Country),
          filter.key
        )
      }
    }
  }

  useMemo(() => {
    setUserCountryAsSuggestion()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userSettings.Country, selectedFilter, filter])

  useEffect(() => {
    if (userSettings && userSettings.Country)
      if (
        filter.key === 'country' &&
        selectedFilter !== getCountryKeyFromName(userSettings.Country)
      ) {
        //fast access user country
        setRecentFilters({
          [filter.key]: getCountryKeyFromName(userSettings.Country)
        })
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterUrlValue])

  const publishedChanged = (e: any) => {
    setPublished((e.target.value === 'published').toString())
  }

  //close panel location change e.g. back button, links
  useEffect(() => {
    if (anchorEl) {
      setAnchorEl(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  return (
    <>
      <FilterMenuLabel
        filter={filter}
        setCurrentPage={setCurrentPage}
        scope={scope}
        selectedFilter={selectedFilter}
        checked={checked}
        setChecked={setChecked}
        emptyFilter={emptyFilter}
        premiumEnabled={premiumEnabled}
        setAnchorEl={setAnchorEl}
        setPremiumEnabled={setPremiumEnabled}
        optionsLength={
          searchedLocalFilterOptions && searchedLocalFilterOptions.length
            ? searchedLocalFilterOptions.length
            : 0
        }
        anchorEl={anchorEl}
        internalBroadcast={internalBroadcast}
      />
      {filter.options &&
        filter.options.length > 0 &&
        !filter.isToggleFilter &&
        filter.key !== 'cfrom' && (
          <Menu
            classes={menuStyles}
            style={{
              paddingRight: '0px',
              marginTop: '12px'
            }}
            elevation={0}
            MenuListProps={{ disablePadding: true }}
            anchorPosition={{
              left: 0,
              top: 0
            }}
            id={'sub-' + filter.key + '-01'}
            className={classes.menu}
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={() => handleClose()}
            TransitionProps={{
              onEnter: () => {
                setIsOpen(true)
              }
            }}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            //fix width/height for tour by changing transform effect
            {...(internalBroadcast &&
            internalBroadcast.includes('openFilterMenu_orderBy')
              ? { TransitionComponent: Fade }
              : {})}
          >
            {minFilterLength && (
              <div className={classes.menuTopBar}>
                <FilterMenuSearchInput
                  handleSearch={handleSearch}
                  handleMenuKeyDown={handleMenuKeyDown}
                  anchorEl={anchorEl}
                />
                {checked && (
                  <div style={{ display: 'flex' }}>
                    <div className={classes.divider}>
                      <div className={classes.seperator}></div>
                    </div>
                    <div
                      className={classes.menuClearButton}
                      onClick={handleClearAllFilterOptions}
                    >
                      {intl.formatMessage({
                        id: 'clear_all',
                        defaultMessage: 'Clear All'
                      })}
                    </div>
                  </div>
                )}
              </div>
            )}

            {!isOpen && (
              <div className={classes.centerLoadingSpinner}>
                <CircularProgress
                  className={classes.loadingSpinner}
                  size={15}
                />
              </div>
            )}
            {isOpen && minFilterLength && (
              <div style={{ width: 220, height: 270 }}>
                <AutoSizer>
                  {({ height, width }) => (
                    <List
                      height={height}
                      width={width}
                      rowHeight={37}
                      rowCount={transformFilterOptions.length}
                      rowRenderer={rowRenderer}
                      overscanRowCount={5}
                    />
                  )}
                </AutoSizer>
              </div>
            )}
            {isOpen && !minFilterLength && (
              <Box data-tut={'reactour_filterbarsub_' + filter.key}>
                {filter.key === 'lastmodified' && scope === 'intranet' && (
                  <RadioGroup
                    aria-label="published"
                    name="published"
                    value={published === 'true' ? 'published' : 'modified'}
                    onChange={publishedChanged}
                    className={classes.radioGroup}
                  >
                    <FormControlLabel
                      id={'inp-modified-01'}
                      value="modified"
                      control={<Radio />}
                      label={intl.formatMessage({
                        id: 'modified',
                        defaultMessage: 'Modified'
                      })}
                      className={classes.radioButton}
                    />
                    <FormControlLabel
                      id={'inp-published-01'}
                      value="published"
                      control={<Radio />}
                      label={intl.formatMessage({
                        id: 'published',
                        defaultMessage: 'Published'
                      })}
                      className={classes.radioButton}
                    />
                  </RadioGroup>
                )}
                {sortFilterOptions(searchedLocalFilterOptions).map(
                  (option: any, index: number) => (
                    <FilterMenuItems
                      key={index}
                      filter={filter}
                      setRecentFilters={setRecentFilters}
                      setCurrentPage={setCurrentPage}
                      handleClose={handleClose}
                      selectedFilter={selectedFilter}
                      setSelectedFilter={(value: string) =>
                        setSelectedFilter(value, filter.key)
                      }
                      menuItemRef={menuItemRef}
                      option={option}
                      index={index}
                      isLastRecentItem={false}
                      isFirstMenuItem={false}
                      modifiedRangeFilters={modifiedRangeFilters}
                    />
                  )
                )}
              </Box>
            )}
            {isOpen && (
              <Box>
                {filter.multiselect && (
                  <div className={classes.multiSelectMenu}>
                    <Button
                      className={`${classes.mulitSelectMenuButton} ${
                        selectedFilter === filterUrlValue
                          ? classes.mulitSelectMenuButtonDisabled
                          : ''
                      }`}
                      onClick={handleApplyMultiSelectFilterOptions}
                      disabled={selectedFilter === filterUrlValue}
                    >
                      {intl.formatMessage({
                        id: 'apply',
                        defaultMessage: 'Apply'
                      })}
                    </Button>
                    <Button
                      className={`${classes.mulitSelectMenuButton} ${
                        !selectedFilter
                          ? classes.mulitSelectMenuButtonDisabled
                          : ''
                      }`}
                      onClick={handleClearAllMultiSelectFilterOptions}
                      disabled={selectedFilter ? false : true}
                    >
                      {intl.formatMessage({
                        id: 'clear_all',
                        defaultMessage: 'Clear All'
                      })}
                    </Button>
                  </div>
                )}
              </Box>
            )}
          </Menu>
        )}
      {filter.key === 'cfrom' && (
        <Menu
          classes={menuStyles}
          style={{
            paddingRight: '0px',
            marginTop: '12px'
          }}
          elevation={0}
          MenuListProps={{ disablePadding: true }}
          anchorPosition={{
            left: 0,
            top: 0
          }}
          id={'sub-' + filter.key + '-01'}
          className={classes.menu}
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => handleClose()}
          TransitionProps={{
            onEnter: () => {
              setIsOpen(true)
            }
          }}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <FilterMenuPeopleSearch
            filter={filter}
            handleClose={handleClose}
            setChecked={setChecked}
          />
        </Menu>
      )}
    </>
  )
}
